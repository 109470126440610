<template>
	<div class="main-contents">
            <div class="mem_container">
                <div class="mem_info_contents">
                    <div class="mem_contents_header">
                        <div class="title-txt">{{title}} 찾기<br/><span>{{title}}를 분실 하셨나요?</span></div>
                    </div>
                    <div class="contents-body cf">
                        <div class="contents-body-box">
                            <img src="/images/mem/hipro_mem_img.png" alt="결과"/>
                            <div class="guide-text" v-html="guideTxt">
                            </div>
                            <div v-if="mberId">
                                <div class="mem-id-box">
                                    <span>{{mberId}}</span>
                                </div>
                            </div>
                            <div class="confirm-btn" @click="pageMove()">
                                로그인
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
	data() {
		return {
			title : '',
            mberId : '',
            forwardData : this.$route.params.forwardData,
            guideTxt : ''
		}
	},

	beforeMount() {
        //console.log(this.forwardData);

		if(this.forwardData) {
            this.title = this.forwardData.title;
            if(this.forwardData.mberId) this.mberId = this.forwardData.mberId;

            this.guideTxtMake();
        }else {
            this.$router.replace('MEM001M01');
        }
	},

	methods : {
		pageMove() {
			this.$router.replace('MEM001M01');
		},

        guideTxtMake() {
            var str = '<span>회원님의 아이디를 찾았습니다!</span><br/>';
            if(this.title == '비밀번호') str = '<span>회원님의 비밀번호가 변경되었습니다!</span><br/>새로운 비밀번호로 로그인 해 주세요!';

            this.guideTxt = str;
        }
	}
}
</script>